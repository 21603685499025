/* You can add global styles to this file, and also import other style files */
$kendo-enable-color-system:false;

$kendo-font-size: 13px;
$kendo-color-primary: #0090B4;


$font-size: $kendo-font-size;
$primary: $kendo-color-primary;

$base-text: #384B52;


$header-height: 72px;
$sub-header-height: 63px;
$cnp-padding: 20%;
//@import "variable";
@import url('https://fonts.googleapis.com/css?family=Overpass');
@import "@progress/kendo-theme-default/dist/all.scss";

/*:root {
  --kendo-color-primary: #0090B4;
}*/

html {
  overflow-y: hidden
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #F5F5F5;
  font-family: 'Overpass';
  font-size: 14px;
  color: $base-text;
}

h1{
    &.primary{
        color:$primary;
    }
    &.no-margin{
        margin:0;
    }
}

.icon-color {
  color: $primary
}

.cnp-button-primary {
  background-color: $primary;
  color: white;
  font-size: $font-size;
  border-radius: 50px;
  margin-top: 25px;

  &:hover {
    background-color: $primary;
    color: white;
  }
}

/******************************************************************************
** Kendo UI overide 
******************************************************************************/

.k-step-list-vertical ~ .k-progressbar {
  width: 0px;
  min-height: 20px;
  left: 50%;
}

.k-step-list-vertical .k-step {
  min-height: 0px;
  max-height: none !important;
}

.k-notification-group {
  z-index: 1000
}

.k-stepper .k-step-done .k-step-indicator {
  border-color: black;
  color: white;
  background-color: black;
}

.k-radio:checked, .k-radio.k-checked {
  border-color: $primary;
  color: white;
  background-color: $primary;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-state-focus,
.k-radio.k-checked.k-focus {
  box-shadow: none; // 0 0 0 2px rgb(255 99 88 / 30%);
}

.k-checkbox:checked, .k-checkbox.k-checked {
  border-color: $primary;
  color: white;
  background-color: $primary;
}
.k-button:disabled, .k-button.k-disabled{
    opacity:0.6;
}
